@font-face {
  font-family: traja;
  src: url(../src/assest/fonts/Trajan-Pro-Bold.woff);
}
body {
  font-size: 15px;
  background: #f2f2f2;
  color: #646c7a;
  margin: 0;
  font-family: traja!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: 0;
  font-weight: normal;
  position: relative;
  padding: 0 0 10px 0;
  font-weight: normal;
  line-height: 120% !important;
  color: #164686;
  margin: 0;
}

h3 {
  font-size: 18px;
}
h6 {
  font-size: 13px;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn {
  font-size: 14px;
  border-radius: 2px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 20px 30px;
  font-weight: 600;
}
.rounded {
  border-radius: .25rem!important;
}
.btn-default {
  background-color: #164686;
  color: #fff;
  border-color: #2573cb;
}

.btn-default:hover {
  background-color: #fff;
  color: #164686;
  border-color: #2573cb;
}

a, .btn {
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}



img {
  width: auto;
  max-width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  line-height: normal;
  vertical-align: middle;
}

.App {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}

.contact-card {
  text-align: center;
  box-sizing: border-box;
  max-width: 25%;
  display: inline-block;
  position: relative;
  padding: 10px;
}

.team-img, .team-one .team-thumb {
  position: relative;
  overflow: hidden;
}

.team-thumb {
  box-shadow: 2px 2px 8px rgba(152, 152, 152, .79);
}
.team-details {
  margin-left: auto;
  margin-right: auto;
  text-align: center; 
  padding: 20px 10px 10px;
}

.img-circle {
  border-radius: 50%;
}

.img-whp {
  width: 100% !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
.scrollToTop {
  bottom: 0px!important;
  background-color: #333!important;
  border-radius: 4px 4px 0 0!important;
  height: 32px!important;
  position: fixed;
  right: 75px;
  width: 48px!important;
  opacity: .9!important;
  transition: all .2s ease-in-out 0s!important;
  padding: 15px!important;
  padding-top: 5px!important;
}
.scrollToTop:hover {
  background-color: #104fa0!important;
}


@media only screen and (max-width: 800.97px) {
  #team-wrapper {
     margin-top: 150px;
  }
}

@media only screen and (max-width: 577.97px) {
  #team-wrapper h1 {
    font-size: 2.2rem
  }
}

@media only screen and (max-width:450.97px) {
  #team-wrapper h1 {
    font-size: 1.75rem
  }
}
