.bottomLinks a {
    color: #222222;
    padding: 5px; 
}
.bottomLinks a:hover {
    color: #15549a; 
}

.sub-footer {
    background-color: #104fa0;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    color: #fff !important;
}
.sub-footer h3 {
    font-size: 1.4rem;
    font-weight: 600;
}
.sub-footer h3, .sub-footer a {
    color: #fff;
}
.sub-footer p {
    font-size: 15px !important;
}