h3 {
    font-size: 18px;
}
    
  .mlo-card {
    position: relative;
    padding: 10px;
  }

  .team-one {
    margin-top: 20px;
  }
  
  .team-img, .team-one .team-thumb {
    position: relative;
    overflow: hidden;
  }
  
  .team-thumb {
    box-shadow: 2px 2px 8px rgba(152, 152, 152, .79);
  }
  .team-details {
    margin-left: auto;
    margin-right: auto;
    text-align: center; 
    padding: 20px 10px 10px;
  }
  
  .btn-default span {
   cursor: pointer;
   display: inline-block;
   position: relative;
   transition: 0.2s;
 }
 .btn-default span:after {
   content: '\00bb';
   position: absolute;
   opacity: 0;
   top: 0;
   right: -10px;
   transition: 0.2s;
   font-size: 22px;
 }
 .btn-default:hover span {
   padding-right: 20px;
 }
 .btn-default:hover span:after {
   opacity: 1;
   right: 0;
 }
