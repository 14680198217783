.main-footer {
    font-size: 12px;
    position: relative;
    padding: 10px 50px;
    background-color: rgb(255, 255, 255);
    color: #333333;
    background-position: center bottom;
    background-repeat: no-repeat;
}

footer a {
    color: #222222;
    text-decoration: underline!important;
}